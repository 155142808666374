<template>
  <a-modal
    v-model:visible="visible"
    title="添加角色"
    :mask-closable="false"
    :get-container="modalContainer"
    :after-close="modalClosed"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item v-bind="validateInfos.name" label="名称">
        <a-input
          v-model:value="roleVo.name"
          placeholder="角色名称"
          allow-clear
          :maxlength="16"
        ></a-input>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea
          v-model:value="roleVo.remark"
          placeholder="角色描述"
          :maxlength="64"
          allow-clear
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, toRefs } from "vue";
import useSysRole from "@/hooks/system/useSysRole";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { save } from "@/api/sys/sysRoleApi";
const useForm = Form.useForm;
export default {
  name: "Add",
  emits: ["close"],
  props: ["modalContainer"],
  setup(props, { emit }) {
    let refresh = false;
    const visible = ref(true);
    const disabled = ref(false);
    const { roleVo } = useSysRole();
    const { sysRoleRules } = useValidateRules();
    const { validateInfos, validate } = useForm(roleVo, sysRoleRules);
    const { showErr, showLoading, closeLoading, showConfirm, showTipMessage } =
      useMessage();
    const modalClosed = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let r = await showConfirm("添加新的角色?");
        if (r) {
          disabled.value = true;
          await showLoading();
          try {
            await save(roleVo);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    return {
      ...toRefs(props),
      visible,
      disabled,
      modalClosed,
      roleVo,
      validateInfos,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
